import { Stack } from '@collinsonx/design-system/core';
import clsx from 'clsx';
import { RenderElement } from 'types/components';

import classes from '../../styles/BlockWrapper.module.css';
import layout from '../../styles/layout.module.css';

type BlockWrapperType = {
  border?: boolean;
  children: RenderElement;
};

export const BlockWrapper = ({ border = true, children }: BlockWrapperType) => (
  <Stack
    className={clsx([classes.sectionBackground, border && layout.border])}
    gap={20}
    p={{ base: '20px', lg: '10px 0', xl: '10px 0' }}
  >
    {children}
  </Stack>
);
