import { WarningIcon } from '@collinsonx/design-system/assets/icons';
import { Box, Flex, Stack } from '@collinsonx/design-system/core';
import clsx from 'clsx';
import React from 'react';

import useLocale from '../../hooks/useLocale';
import classes from './BookingAmendmentPaymentInfoMessage.module.css';

interface BookingAmendmentPayInFullInfoMessageProps {
  showBorderBottom?: boolean;
  showBorderTop?: boolean;
}

export const BookingAmendmentPayInFullInfoMessage = ({
  showBorderBottom = false,
  showBorderTop = false,
}: BookingAmendmentPayInFullInfoMessageProps) => {
  const translations = useLocale();

  return (
    <Box
      className={clsx([
        classes.messageStyle,
        { [classes.showBorderTop]: showBorderTop },
        { [classes.showBorderBottom]: showBorderBottom },
      ])}
    >
      <Stack>
        <Flex align="top" gap="xs">
          <Box pt={2}>
            <WarningIcon style={{ height: 21, width: 21 }} />
          </Box>
          <p className={classes.text}>
            {translations.booking.payment.amendmentPaymentPolicy}
          </p>
        </Flex>
      </Stack>
    </Box>
  );
};
