import dayjs from 'dayjs';

export const formatEntitlementsDate = (date: string, timezone?: string) => {
  if (!date) return '';

  const newDate = dayjs(date).tz(timezone);

  const year = newDate.year();
  const month = newDate.month() + 1;
  const day = newDate.date();

  return `${day}.${month}.${year}`;
};
