import { ArrowLeft } from '@collinsonx/design-system/assets/icons';
import { Flex, NavLink } from '@collinsonx/design-system/core';
import { getItem, logAction, sendMobileEvent } from '@lib';
import clsx from 'clsx';
import useOutlet from 'hooks/outlet';
import usePayload from 'hooks/payload';
import useLocale from 'hooks/useLocale';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { FAQLink } from 'utils/FAQLinks';

import {
  ANALYTICS_TAGS,
  BOKING_MODE_STATE,
  BOOKING_MODE,
  MOBILE_ACTION_BACK,
} from '../../constants';
import classes from './TopBarLinks.module.css';

interface TopBarLinksProps {
  page?: string;
  showBackButton?: boolean;
}

function TopBarLinks({ page, showBackButton }: TopBarLinksProps) {
  const { membershipType, payload, referrerUrl } = usePayload();
  const { lounge } = useOutlet();
  const Booking_Mode = getItem(BOKING_MODE_STATE);
  const router = useRouter();

  const handleClickBack = useCallback(() => {
    if (window && !referrerUrl) {
      sendMobileEvent(window, MOBILE_ACTION_BACK);
    }
    logAction(
      'backToLounge',
      `${ANALYTICS_TAGS.ON_HIT_BACK_BUTTON}${page}`,
      null
    );
  }, [referrerUrl]);

  const handleClickAmendBack = () => {
    router.back();
  };

  const translations = useLocale();

  return (
    <Flex justify="space-between">
      {showBackButton ? (
        <NavLink
          className={clsx([classes.navLink, classes.anchor])}
          href="#"
          label={translations.lounge.topBarLinks.back}
          leftSection={<ArrowLeft size="1rem" stroke={1.5} />}
          onClick={handleClickAmendBack}
          target="_top"
        />
      ) : Booking_Mode === BOOKING_MODE.EDIT ? (
        <div></div>
      ) : (
        <NavLink
          className={clsx([classes.navLink, classes.anchor])}
          href={referrerUrl || '#'}
          label={`${translations.lounge.topBarLinks.backToLounge} ${(
            lounge?.loungeName || ''
          ).toUpperCase()}`}
          leftSection={<ArrowLeft size="1rem" stroke={1.5} />}
          onClick={handleClickBack}
          target="_top"
        />
      )}
      <NavLink
        className={classes.anchor}
        href={FAQLink(
          payload?.accountProvider,
          translations.booking.flightDetails.localeValue,
          membershipType
        )}
        label={translations.lounge.topBarLinks.faqs}
        target="_blank"
      />
    </Flex>
  );
}

export default TopBarLinks;
