import { LoaderLifestyleHorizontal } from '@collinsonx/design-system';
import { RenderElement } from 'types/components';

interface CustomLazyLoadProps {
  children?: RenderElement;
  hideIf?: boolean;
  loader?: RenderElement;
  loading?: boolean;
  wrapper?: React.FC<{ children: RenderElement }>;
}

export default function LazyLoad({
  children = null,
  hideIf = false,
  loader = <LoaderLifestyleHorizontal />,
  loading = true,
  wrapper: Wrapper,
}: Readonly<CustomLazyLoadProps>) {
  if (hideIf) return null;

  const componentToRender = Wrapper ? <Wrapper>{children}</Wrapper> : children;
  const loaderToRender = Wrapper ? <Wrapper>{loader}</Wrapper> : loader;

  const componentState = loading ? loaderToRender : componentToRender;

  return componentState as RenderElement;
}
