import useOutlet from 'hooks/outlet';
import { useMemo } from 'react';
import { getCurrencySymbol } from 'utils/currencysymbol';

type UsePriceType = (props: {
  entitlementsCount: number;
  guestsCount: number;
}) => {
  currencySymbol: string;
  loadingPrice: boolean;
  totalPrice: number;
  totalPriceFormatted: string;
};

const usePrice: UsePriceType = ({ entitlementsCount = 0, guestsCount = 0 }) => {
  const { lounge } = useOutlet();

  const { currency, saleprice } = lounge || {};

  const priceData = useMemo(() => {
    if (!saleprice || !currency) return {};

    const sum = saleprice * guestsCount;
    const totalPrice = sum - saleprice * entitlementsCount;

    const currencySymbol = getCurrencySymbol(currency);

    const totalPriceFormatted = `${currencySymbol} ${totalPrice.toFixed(2)}`;

    return {
      currencySymbol,
      loadingPrice: false,
      totalPrice,
      totalPriceFormatted,
    };
  }, [saleprice, currency, guestsCount, entitlementsCount]);

  return {
    currencySymbol: '',
    loadingPrice: true,
    totalPrice: 0,
    totalPriceFormatted: '0.00',
    ...priceData,
  };
};

export default usePrice;
