import { FlightDetails } from '@collinsonx/utils';
import { createContext } from 'react';

interface FlightContextProps {
  getFlight: () => FlightDetails;
  setFlight: (flight: FlightDetails) => void;
}

const defaultContext = {
  getFlight: () => JSON.parse(sessionStorage.getItem('flightdetail') ?? '{}'),
  setFlight: (flight: FlightDetails) => {
    sessionStorage.setItem('flightdetail', JSON.stringify(flight));
  },
};

export const FlightContext = createContext<FlightContextProps>(defaultContext);

// temporary adding these until the work on removing the context is done:
// eventually they should be moved to utility folder or something

export const setFlight = (flight: FlightDetails) => {
  sessionStorage.setItem('flightdetail', JSON.stringify(flight));
};

export const getFlight = () =>
  JSON.parse(sessionStorage.getItem('flightdetail') ?? '{}');
