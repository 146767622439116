import { Client } from '@collinsonx/constants/enums';
import { InfoIcon } from '@collinsonx/design-system/assets/icons';
import BookingLightbox from '@collinsonx/design-system/components/bookinglightbox';
import Heading from '@collinsonx/design-system/components/heading/Heading';
import { ActionIcon, Flex, Text } from '@collinsonx/design-system/core';
import { BlockWrapper } from '@components/BlockWrapper/BlockWrapper';
import LazyLoad from '@components/LazyLoad/LazyLoad';
import QuantityInput from '@components/QuantityInput';
import SvgAdapter from '@components/SvgAdapter/SvgAdapter';
import { BookingContext } from 'context/bookingContext';
import useOutlet from 'hooks/outlet';
import usePayload from 'hooks/payload';
import useEntitlements from 'hooks/useEntitlements';
import useLocale from 'hooks/useLocale';
import { useContext, useEffect, useState } from 'react';
import colors from 'ui/colour-constants';
import { formatEntitlementsDate } from 'utils/entitlements';
import { replaceTranslationPlaceholder } from 'utils/translations';

type EntitlementsBlockProps = {
  setLoading?: (val: boolean) => void;
};

export default function EntitlementsBlock({
  setLoading,
}: EntitlementsBlockProps) {
  const { lounge } = useOutlet();
  const [selectedEntitlementsCount, setSelectedEntitlementsCount] =
    useState<number>(0);
  const [entitlementsModalOpen, setEntitlementsModalOpen] =
    useState<boolean>(false);

  const translations = useLocale();
  const { booking, setBooking } = useContext(BookingContext);
  const { entitlementsData, fetchEntitlements, loadingEntitlements } =
    useEntitlements();
  const { adults, children } = booking;
  const { membershipType } = usePayload();

  const entitlementsCount =
    entitlementsData?.getEntitlementsForProduct.length ?? 0;
  const entitlemenstExpiryDate =
    entitlementsData?.getEntitlementsForProduct?.[0]?.expiryDate;
  const taxableGuests = adults + children;

  const maxEntitlementsCount = Math.min(entitlementsCount, taxableGuests);

  useEffect(() => {
    if (membershipType === Client.AMEX_UK) fetchEntitlements();
  }, []);

  useEffect(() => {
    if (setLoading) setLoading(loadingEntitlements);
  }, [loadingEntitlements]);

  useEffect(() => {
    handleSelectEntitlementsChange(maxEntitlementsCount);
  }, [taxableGuests]);

  useEffect(() => {
    if (entitlementsData)
      handleSelectEntitlementsChange(
        Math.min(entitlementsCount, taxableGuests)
      );
  }, [entitlementsData]);

  const handleSelectEntitlementsChange = (val: number) => {
    setSelectedEntitlementsCount(val);
    setBooking({
      selectedEntitlementsCount: val,
    });
  };

  return (
    <LazyLoad
      hideIf={
        !entitlementsData?.getEntitlementsForProduct.length &&
        !loadingEntitlements
      }
      loading={loadingEntitlements}
      wrapper={BlockWrapper}
    >
      <BookingLightbox
        ctaCancel={translations.booking.entitlements.infoModal.btnClose}
        onClose={() => setEntitlementsModalOpen(false)}
        open={entitlementsModalOpen}
      >
        <Flex align="center" justify="center" p="10px 0" wrap="wrap">
          <Heading as="h2" lineHeight={1.2} margin={0} padding={0}>
            {translations.booking.entitlements.infoModal.title}
          </Heading>
          <Text m="10px 0 10px 0" size="xl" ta="center">
            {translations.booking.entitlements.infoModal.text}
          </Text>
        </Flex>
      </BookingLightbox>
      <Flex align="center" direction="row" gap="16px " justify="space-between">
        <Heading as="h2" margin={0} padding={0}>
          {translations.booking.entitlements.title}
        </Heading>
        <ActionIcon
          aria-label="Settings"
          onClick={() => setEntitlementsModalOpen(true)}
          role="button"
          style={{ marginTop: '0.3rem' }}
          variant="transparent"
        >
          <SvgAdapter fill={colors.theme}>
            <InfoIcon />
          </SvgAdapter>
        </ActionIcon>
      </Flex>
      <Text style={{ fontWeight: 'bold' }}>
        {replaceTranslationPlaceholder(
          translations.booking.entitlements.countAndExpiryDate,
          {
            count: entitlementsCount,
            date: formatEntitlementsDate(
              entitlemenstExpiryDate,
              lounge?.timezone
            ),
          }
        )}
      </Text>
      <Text>{translations.booking.entitlements.inputLabel}</Text>
      <QuantityInput
        disabled={loadingEntitlements}
        max={maxEntitlementsCount}
        min={0}
        onChange={(val) => handleSelectEntitlementsChange(val as number)}
        value={selectedEntitlementsCount}
      />
    </LazyLoad>
  );
}
