import { WarningIcon } from '@collinsonx/design-system/assets/icons';
import Heading from '@collinsonx/design-system/components/heading/Heading';
import { Anchor, Box, Flex, Grid, Text } from '@collinsonx/design-system/core';
import { UseFormReturnType } from '@collinsonx/design-system/form';
import { BlockWrapper } from '@components/BlockWrapper/BlockWrapper';
import Notification from '@components/Notification';
import { sendMobileEvent } from '@lib';
import useLocale from 'hooks/useLocale';
import React, { useCallback } from 'react';
import colors from 'ui/colour-constants';
import { replaceTranslationPlaceholder } from 'utils/translations';

import { MAX_GUESTS, MOBILE_ACTION_BACK } from '../../constants';
import QuantityInput from '../QuantityInput';
import classes from './Guestinfo.module.css';

export interface GuestInfoProps {
  form: UseFormReturnType<any, any>;
  guestError: boolean;
  loading: boolean;
  referreUrl: string;
}

const GuestInfo = ({
  form,
  guestError,
  loading,
  referreUrl,
}: GuestInfoProps) => {
  const translations = useLocale();

  const maxAdultsCount = MAX_GUESTS - form.getInputProps('children').value;
  const maxChildrenCount = MAX_GUESTS - form.getInputProps('adults').value;
  const isMaxTaxableGuestCountReached =
    maxAdultsCount + maxChildrenCount === MAX_GUESTS;

  const handleClick = useCallback(() => {
    if (top) {
      if (referreUrl) {
        top.location.href = referreUrl;
      } else {
        const windowObj: any = window;
        sendMobileEvent(windowObj, MOBILE_ACTION_BACK);
      }
    }
  }, [referreUrl]);

  return (
    <BlockWrapper>
      <Heading as="h2" margin={0} padding={0}>
        {translations.booking.guestDetails.title}
      </Heading>
      <Flex align="top" direction="column" gap={8}>
        <Flex
          align="top"
          className={isMaxTaxableGuestCountReached ? classes.warning : ''}
          direction="row"
          gap={8}
        >
          <Box pt={2}>
            <WarningIcon style={{ height: 21, width: 21 }} />
          </Box>
          <Text>
            {replaceTranslationPlaceholder(
              translations.booking.guestDetails.description,
              {
                guests: MAX_GUESTS,
              }
            )}
          </Text>
        </Flex>
        {guestError ? (
          <Box className={classes.errorContainer}>
            <Notification>
              {replaceTranslationPlaceholder(
                translations.booking.guestDetails.errors.capacity,
                {
                  guests: MAX_GUESTS,
                }
              )}
            </Notification>
          </Box>
        ) : (
          ''
        )}
      </Flex>
      <Grid>
        <Grid.Col span={{ lg: 6 }}>
          <QuantityInput
            disabled={loading}
            label={translations.booking.guestDetails.adultsInput.label}
            max={maxAdultsCount}
            min={1}
            onChange={form.getInputProps('adults').onChange}
            subText={translations.booking.guestDetails.adultsInput.description}
            value={form.getInputProps('adults').value}
          />
        </Grid.Col>

        <Grid.Col span={{ lg: 6 }}>
          <QuantityInput
            disabled={loading}
            label={translations.booking.guestDetails.childrenInput.label}
            max={maxChildrenCount}
            min={0}
            onChange={form.getInputProps('children').onChange}
            subText={
              translations.booking.guestDetails.childrenInput.description
            }
            value={form.getInputProps('children').value}
          />
        </Grid.Col>

        <Grid.Col span={{ lg: 6 }}>
          <QuantityInput
            disabled={loading}
            label={translations.booking.guestDetails.infantsInput.label}
            max={MAX_GUESTS}
            onChange={form.getInputProps('infants').onChange}
            subText={translations.booking.guestDetails.infantsInput.description}
            value={form.getInputProps('infants').value}
          />
        </Grid.Col>
      </Grid>
      <Text size="sm">
        {translations.booking.guestDetails.loungeTerms.line1}
        <Anchor color={colors.blue} onClick={handleClick}>
          {translations.booking.guestDetails.loungeTerms.link}
        </Anchor>{' '}
        {translations.booking.guestDetails.loungeTerms.line2}
      </Text>
    </BlockWrapper>
  );
};

export default GuestInfo;
