import { AccountProvider, Client } from '@collinsonx/constants/enums';

const { Mastercard_MCAE } = Client;

export const FAQLink = (
  accountProvider?: string,
  locale?: string,
  membershipType?: string
) => {
  const faqLinkLK = `https://portal.loungekey.com/${locale}/elo/faq/`;
  const faqLinkPP = `https://memberhelp.prioritypass.com/${locale}/support/home`;
  const faqLinkMCAE = `https://airport.mastercard.com/${locale}/faq`;

  if (membershipType === Mastercard_MCAE) return faqLinkMCAE;
  if (accountProvider === AccountProvider.LK) return faqLinkLK;
  if (accountProvider === AccountProvider.PP) return faqLinkPP;

  return '';
};
