import { logDataError } from '@lib';

import { BookingError } from '../constants';

type SafeFunction = {
  (expression: Function): any;
};

export const safe: SafeFunction = (expression) => {
  let result;

  try {
    result = expression();
  } catch (er) {
    logDataError(BookingError.GENERIC_ERROR, {
      customError: er as Error,
    });
  }

  return result;
};
