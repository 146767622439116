"use strict";
var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/mutations/linkAccount.ts
var linkAccount_exports = {};
__export(linkAccount_exports, {
  default: () => linkAccount_default
});
module.exports = __toCommonJS(linkAccount_exports);

// src/apollo.ts
var import_client = require("@apollo/client");
var import_link_error = require("@apollo/link-error");
var import_deepmerge = __toESM(require("deepmerge"));
var import_isEqual = __toESM(require("lodash/isEqual"));
var import_react = require("react");
var import_session = require("supertokens-auth-react/recipe/session");
var import_client2 = require("@apollo/client");
var graphqlUrl = process.env.NEXT_PUBLIC_PRODUCTION_API_URL;
var checkProtected = () => {
  if (typeof window !== "undefined") {
    const { pathname } = window.location;
    return !pathname.startsWith("/auth") && !pathname.startsWith("/signup");
  }
};
var redirectLogin = () => {
  if (typeof window !== "undefined") {
    window.location.href = `/auth/login/?redirecturl=${window.location.pathname + window.location.search}`;
  }
};
var withTokenRefresh = new import_client.ApolloLink((operation, forward) => {
  if (checkProtected()) {
    return (0, import_client.fromPromise)(
      (0, import_session.attemptRefreshingSession)().then((success) => {
        if (!success) {
          redirectLogin();
        }
        return (0, import_client.toPromise)(forward(operation));
      })
    );
  }
  return forward(operation);
});
var httpLink = new import_client.HttpLink({
  uri: graphqlUrl,
  credentials: "include"
});
var errorLink = (0, import_link_error.onError)(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(
      ({ message, locations, path }) => console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  }
  if (networkError) {
    console.log(
      `[Network error]: ${networkError}. Backend is unreachable. Is it running?`,
      `[Graphql URL]: ${graphqlUrl}`
    );
  }
});

// src/mutations/linkAccount.ts
var linkAccount = import_client2.gql`
  mutation LinkAccount($linkedAccountInput: LinkedAccountInput) {
    linkAccount(linkedAccountInput: $linkedAccountInput) {
      id
      externalID
      consumer {
        id
        fullName
        firstName
        lastName
        dateOfBirth
        emailAddress
        phone
        crmId
        linkedAccounts {
          id
        }
        createdAt
        updatedAt
      }
      provider
      membershipID
      membershipType
      createdAt
      updatedAt
    }
  }
`;
var linkAccount_default = linkAccount;
