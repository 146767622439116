const FLIGHTNUMBER_VALIDATION =
  /^([A-Za-z]{2}|[A-Za-z]{3}|[A-Za-z][0-9]|[0-9][A-Za-z])([0-9]+[A-Za-z]?)$/gi;

const EASYJET_ICAO_CODES = ['EZY', 'EJU', 'EZS'];

export function validateFlightNumber(
  flightNo: string
): [boolean, string | undefined, string | undefined] {
  const match = [...flightNo.matchAll(FLIGHTNUMBER_VALIDATION)];

  if (!match) {
    return [false, undefined, undefined];
  }

  // we need to map the easyjet ICAO codes to U2 since all the info for their flights is served using U2 code
  const carrierCode =
    match[0]?.[1] && EASYJET_ICAO_CODES.includes(match[0]?.[1])
      ? 'U2'
      : match[0]?.[1];

  const carrierLen = carrierCode?.length ?? 0;
  if (carrierLen < 1 || carrierLen > 3) {
    return [false, undefined, undefined];
  }

  const flightNoLen = match[0]?.[2]?.length ?? 0;
  if (flightNoLen < 1 || flightNoLen > 5) {
    return [false, undefined, undefined];
  }

  return [true, carrierCode, match[0][2]];
}
