import { InputLabel } from '@collinsonx/design-system';
import Heading from '@collinsonx/design-system/components/heading/Heading';
import { Flex } from '@collinsonx/design-system/core';
import { DatePickerInput } from '@collinsonx/design-system/date';
import { UseFormReturnType } from '@collinsonx/design-system/form';
import { BlockWrapper } from '@components/BlockWrapper/BlockWrapper';
import { logAction } from '@lib';
import { IconCalendar } from '@tabler/icons-react';
import 'dayjs/locale/es';
import useLocale from 'hooks/useLocale';

import { ANALYTICS_TAGS } from '../../../constants';
import classes from './FlightInfo.module.css';

interface FlightInfoProps {
  form?: UseFormReturnType<any, any>;
  loading: boolean;
  page?: string;
  tags?: ANALYTICS_TAGS[];
}

export interface AvailabilitySlot {
  endDate: string;
  maxDuration: number;
  slotConfigurationId?: string;
  startDate: string;
}

export const FlightInfo = ({
  form,
  loading,
  page = '',
  tags = [],
}: FlightInfoProps) => {
  const translations = useLocale();

  return (
    <BlockWrapper>
      <Heading as="h2" lineHeight={1} margin={0} padding={0}>
        {translations.booking.flightDetails.title}
      </Heading>
      <Flex className={classes.containerFlex} justify="space-between">
        <DatePickerInput
          classNames={{ day: classes.day, root: classes.datePickerInput }}
          data-testid="flightDate"
          disabled={loading}
          label={translations.booking.flightDetails.dateInput.label}
          leftSection={<IconCalendar size="1.5rem" stroke={1.5} />}
          locale={translations.booking.flightDetails.localeValue}
          maw={400}
          minDate={new Date()}
          onClick={() => logAction(page, tags[0])}
          placeholder={translations.booking.flightDetails.dateInput.placeholder}
          style={{ textWrap: 'nowrap' }}
          w={270}
          withAsterisk
          {...form?.getInputProps('departureDate')}
        />
        <InputLabel
          data-testid="flightNumber"
          disabled={loading}
          error="invalid flight number"
          label={translations.booking.flightDetails.numberInput.label}
          placeholder={
            translations.booking.flightDetails.numberInput.placeholder
          }
          w={270}
          withAsterisk
          {...form?.getInputProps('flightNumber')}
          isCapitalLetters
          onClick={() => logAction(page, tags[1])}
        />
      </Flex>
    </BlockWrapper>
  );
};
